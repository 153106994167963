import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { OrangeButton } from "../components/button-orange"

const SuccessPage = (props) => {
    return (
        <Layout>
            <Seo
                title="Submission Successful! - Iron West Group Affordable Heavy Equipment Sales"
                description="Thank you for reaching out to Iron West Group about our Affordable Heavy Equipment Sales, your submission was successful and we will do our best to respond within the next 24 hours, Thank You!"
                location={props.location.pathname}
            />
            <div className="bg-lightgray py-16 px-5 lg:px-10">
                <div className="container mx-auto text-center">
                    <div className="max-w-lg mx-auto">
                        <h3 className="font-bold text-3xl pb-3">Submission Successful!</h3>
                        <p className="pb-5">
                            Our team has received your message and will get back to you within 24 hours. Thank you for your interest in our equipment!
                        </p>

                        <div className="space-y-3">
                            <OrangeButton buttonPage="/inventory" buttonText="Browse Inventory" buttonTitle='Browse Inventory' buttonAlt='Iron West Group - Browse Inventory' />

                            <OrangeButton buttonPage="/" buttonText="Return Home" buttonTitle='Go To Iron West Group Home Page' buttonAlt='Iron West Group - Home Page' />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default SuccessPage